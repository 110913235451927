import React from 'react';

import I18n from '@seudev/x-i18n';

import Loading, { LoadingContextProvider } from './components/loading/Loading';
import Navbar from './components/navbar/Navbar';
import Home from './sections/home/Home';
import Timeline from './sections/timeline/Timeline';
import WhoIAm from './sections/who-i-am/WhoIAm';
import Skills from './sections/skills/Skills';
import Knowledge from './sections/knowledge/Knowledge';
import Contact from './sections/contact/Contact';
import Footer from './sections/footer/Footer';
import BackToTopButton from './components/back-to-top-button/BackToTopButton';
import en from './i18n/en';

const navbarHeight = 52;

const i18nConfig = {
  fallback: en,
  langs: ['en', 'pt-BR'],
  messagesProvider: lang => import(`./i18n/${lang}`)
};

const App = () => {
  const minHeightToShow = (window.innerHeight - navbarHeight);

  return (
    <I18n config={i18nConfig}>
      <LoadingContextProvider>
        <Loading full={true} />
        <Navbar minHeightToShow={minHeightToShow} minWidthToHide={769} />
        <Home />
        <WhoIAm />
        <Timeline />
        <Skills />
        <Knowledge />
        <Contact />
        <Footer />
        <BackToTopButton minHeightToShow={minHeightToShow} />
      </LoadingContextProvider>
    </I18n>
  );
};

export default App;
