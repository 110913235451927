import React, { useState, useEffect } from 'react';

import './backToTopButton.scss';

const BackToTopButton = ({ minHeightToShow = -1 }) => {
    const [active, setActive] = useState(false);


    const onButtonClick = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        const canShowButton = () => (document.body.scrollTop >= minHeightToShow || document.documentElement.scrollTop >= minHeightToShow);

        const handleButtonVisibility = () => setActive(canShowButton());

        handleButtonVisibility();
        window.addEventListener("scroll", handleButtonVisibility);

        return () => {
            window.addEventListener("scroll", handleButtonVisibility);
        };
    }, [minHeightToShow]);

    return (
        <span id="back-to-top" className={`${active ? '' : 'hidden'}`} onClick={onButtonClick}>
            <span>
                <i className="fa fa-chevron-up" />
            </span>
        </span>
    );

};

export default BackToTopButton;
