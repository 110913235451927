/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState, useEffect } from 'react';

import { Message } from '@seudev/x-i18n';

import LanguageDropdown from './LanguageDropdown';
import './navbar.scss';

const Navbar = ({ minHeightToShow = -1, minWidthToHide = 0, links = [] }) => {
    const [hidden, setHidden] = useState(true);
    const [activeBurger, setActiveBurger] = useState(false);

    const closeNavbar = () => setActiveBurger(false);

    const onClickBurger = e => {
        e.preventDefault();
        setActiveBurger(active => !active);
    };

    useEffect(() => {
        const canShowNavbar = () => {
            if (window.innerWidth < minWidthToHide) {
                return true;
            }
            return (document.body.scrollTop >= minHeightToShow || document.documentElement.scrollTop >= minHeightToShow);
        }

        const handleNavbarVisibility = () => setHidden(!canShowNavbar());

        window.addEventListener("resize", handleNavbarVisibility);
        window.addEventListener("scroll", handleNavbarVisibility);

        return () => {
            window.removeEventListener("resize", handleNavbarVisibility);
            window.addEventListener("scroll", handleNavbarVisibility);
        };
    }, [minHeightToShow, minWidthToHide]);

    const isActiveClass = activeBurger ? 'is-active' : '';

    return (
        <nav id="main-navbar" className={`navbar is-fixed-top ${hidden ? 'is-hidden' : ''}`} role="navigation" aria-label="main navigation">
            <div className="container">
                <div className="navbar-brand">
                    <a className="navbar-item" href="#home" onClick={closeNavbar}><Message id="brand" /></a>

                    <a role="button" className={`navbar-burger burger ${isActiveClass}`} aria-label="menu" aria-expanded="false" data-target="main-navbar-menu" onClick={onClickBurger}>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                </div>

                <div id="main-navbar-menu" className={`navbar-menu ${isActiveClass}`}>
                    <div className="navbar-end">
                        {links.map(link => (<a key={link.href} className="navbar-item" href={link.href} onClick={closeNavbar}><Message id={link.title} /></a>))}
                        <LanguageDropdown onChange={closeNavbar} />
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
