/* eslint-disable no-template-curly-in-string */

const messages = {
    id: "en",
    messages: {
        lang: {
            en: "English",
            "pt-BR": "Portuguese (Brazil)"
        },
        unit: {
            time: {
                and: 'and',
                year: "year${value > 1 ? 's' : ''}",
                month: "month${value > 1 ? 's' : ''}",
            }
        },
        brand: "Thomás Sousa Silva",
        slogan: "A code lover moved by electronic music!",
        sections: {
            whoIAm: {
                title: "Who I Am",
                content: [
                    "My name is Thomas Sousa, I am ${yearsOld} years old, I'm Brazilian and I live in the capital of Pernambuco: Recife, I'm that dreamer who gets excited about his creations and is happy to see them making people's lives easier. Since I was little I used to create new things, but at that time, my creations were made manually: with wood, nails and what else I found necessary.",
                    `The sound of the tools mixed with the songs I was listening. In the future I would discover that it was electronic music - the musical style of my life. When I was a teenager I discovered the game Tibia, I spent hours playing it and listening to electronic music.
                     Further on, I discovered that I could have a version customized version of the game for me. To get that, I edited .lua files to change how the game works. That is, I already programmed without knowing that I was programming and that it would be one more passion in my life.`,
                    "Today, I am officially a programmer: I have ${businessExperience} of professional experience and a few more years working on personal projects. I have worked as a technical leader of a team, requirements analyst and full stack developer. I majored in Systems Analysis and Development and my professional goal is developing simple solutions that aggregate value for the client and facilitate the life of other people. I like projects that can challenge me and make me grow."
                ]
            },
            timeline: {
                title: "Timeline",
            },
            skills: {
                title: "Skills",
                content: {
                    businessAnalysis: {
                        title: "Business Analysis",
                        description: "Facility to understand the business, problems and wishes of the client, with this I can create more accurate and personalized solutions."
                    },
                    leadership: {
                        title: "Leadership",
                        description: "Ability to lead development teams to achieve goals. Naturally, I held that position, due to my technical knowledge and good communication with the team and business area."
                    },
                    creativity: {
                        title: "Creativity",
                        description: "Ability to create creative solutions, such as libraries and frameworks, to facilitate the production of other software and thus reduce time and effort."
                    },
                    architectureDesign: {
                        title: "Architecture Design",
                        description: "Experience in web application architecture design and ability to model entities, services, utilities, exception handling, validations, monolithic applications and microservices.",
                    },
                    refactoring: {
                        title: "Refactoring",
                        description: "Reducing rework and making it easier to use some APIs are things I enjoy doing. Experience in refactoring of methods until complete applications.",
                    },
                    databaseModeling: {
                        title: "Database Modeling",
                        description: "Ability and experience in modeling relational databases, mainly with PostgreSQL, creating robust and flexible models that represent the needs of the user.",
                    },
                    sqlScripts: {
                        title: "SQL Scripts",
                        description: "Complex SQL scripts creation for queries, updates, insertions, deletions and database migrations, mainly for PostgreSQL.",
                    },
                    webDesign: {
                        title: "Web Design",
                        description: "Front-End Skills with design and programming using React, Javascript, HTML, CSS, Bootstrap and JQuery. In addition to possessing a bit of knowledge in Typescript and Angular.",
                    },
                    javaExpert: {
                        title: "Java Expert",
                        description: "Java is my favorite programming language. I use this language for over ${javaExperience}, during that journey, I used the Java to create standalone and Web applications, beyond libraries and structures. I have experience with Java SE and Jakarta EE (Formerly known as Java EE) and Spring.",
                    },
                }
            },
            knowledge: {
                title: "Knowledges",
                content: [
                    "Java",
                    "Javascript",
                    "Golang",
                    "React",
                    "Angular",
                    "Typescript",
                    "Node",
                    "Docker",
                    "Shell script",
                    "Microservices",
                    "MicroProfile",
                    "Prometheus",
                    "Graylog",
                    "Kafka",
                    "Keycloak",
                    "Jenkins",
                    "Nginx",
                    "Quarkus",
                    "Bootstrap",
                    "CSS",
                    "Sass",
                    "Less",
                    "HTML",
                    "JQuery",
                    "SEO",
                    "PWA",
                    "Spring",
                    "Hibernate",
                    "SQL",
                    "JPA",
                    "JAX-RS",
                    "CDI",
                    "MySQL",
                    "PostgreSQL",
                    "Bean Validation",
                    "Payara Micro",
                    "GlassFish",
                    "Tomcat",
                    "Cypress",
                    "JUnit",
                    "JSP",
                    "JSF",
                    "Reflections",
                    "Maven",
                    "Git",
                    "SVN",
                    "GitHub",
                    "GitLab",
                    "Bitbucket",
                    "VSCode",
                    "Eclipse",
                    "Netbeans",
                    "Windows",
                    "Linux"
                ]
            },
            contact: {
                title: "Contact",
                subtitle: "Do you want to know more about my work?",
                description: "How about you send me an email with your questions? It will be a pleasure to answer them."
            },
        },
        timelines: {
            "timeline": {
                title: "Timeline",
                events: {
                    "business_senior_full_stack_developer_g4f": {
                        summary: "Senior Full Stack Developer, G4F - Recife",
                        description: [],
                        importantPoints: [
                            "I am a technical leader of a team of programmers and provide support in solving problems for other teams;",
                            "I work as a requirements analyst, understanding the current scenario and the client's objectives, to extract requirements and design solutions;",
                            "I am responsible for developing / maintaining several back ends made in Java, Spring and Quarkus;",
                            "I work in the analysis and solution of business and system problems;",
                            "I work as a front-end, creating interfaces with React, HTML, Javascript, CSS, Material Design and Bootstrap;",
                            "I create libraries in Java and React to reduce the cost, time, complexity and maintenance of other projects;",
                            "I develop Java REST APIs for internal use;",
                            "I perform backend optimizations and SQL queries;",
                            "I develop PostgreSQL database models;",
                            "perform database migration from one vendor to another, such as PostgreSQL and SQL Server;",
                            "I develop end to end tests;",
                            "I create pipelines for continuous integration, using Jenkins, Docker, Shell script and Linux;",
                            "I integrate with internal systems and the Ministry of Health;",
                            "I create automations for some routines, such as database imports and data reprocessing;",
                            "acquired business knowledge related to the health area, such as bed regulation and authorization of hospital procedures;",
                            "work with the Scrum Methodology;",
                            "I provide services to the State Health Secretariat of Pernambuco.",
                        ],
                        keywords: [
                            "Technical Leader",
                            "Front-end",
                            "Back-end",
                            "Requirements Analysis",
                            "Java",
                            "SQL",
                            "Spring",
                            "React",
                            "Javascript",
                            "CI",
                        ],
                        end: "Today"
                    },
                    "business_requirements_analyst_montreal": {
                        summary: "Requirements Analyst, M.I. Montreal - Recife",
                        description: [],
                        importantPoints: [
                            "I worked as a requirements analyst, understanding the current scenario and the client's objectives, to extract requirements and design solutions;",
                            "I acquired knowledge of businesses related to health, such as hospital shifts, bed regulation and training of health professionals;",
                            "I worked in the analysis and solution of business and system problems;",
                            "I worked with the Scrum Methodology;",
                            "I worked for the State Health Department of Pernambuco.",
                        ],
                        keywords: [
                            "Requirements Analysis",
                            "Software Modeling",
                        ],
                    },
                    "business_full_stack_developer_montreal": {
                        summary: "Full Stack Developer, M.I. Montreal - Recife",
                        description: [],
                        importantPoints: [
                            "I developed the FormaSUS system, which is used by SES, to manage and provide annually, hundreds of scholarships, of technical and higher level;",
                            "I developed the PMR system, which helps in the regulation of hospital beds, providing several indicators for decision making;",
                            "I was responsible for developing / maintaining several back-ends made in Java and Spring;",
                            "acted as front-end, creating interfaces with bootstrap, Javascript, JQuery and CSS;",
                            "created libraries in Java to reduce cost, time, complexity and maintenance of other projects;",
                            "performed back-end optimizations and SQL queries;",
                            "performed database migration from one vendor to another, such as PostgreSQL and Oracle;",
                            "modeled PostgreSQL databases;",
                            "I create pipelines for continuous integration, using Jenkins, Shell script and Linux;",
                            "I worked with Scrum and Extreme programming Methodologies (XP);",
                            "I worked for the State Health Secretariat of Pernambuco.",
                        ],
                        keywords: [
                            "Front-end",
                            "Back-end",
                            "Java",
                            "SQL",
                            "Spring",
                            "Javascript",
                            "CI",
                        ],
                    },
                    "project_formasus": {
                        summary: "Development of FormaSUS system, SES - Recife",
                        description: [
                            `Development of <a href="\${link.href}" target="\${link.target}">FormaSUS</a> system, realized in set with the <i>STI</i> and <i>SEGTES</i> for the State Health Department in Pernambuco (SES).`,
                            `<a href="\${link.href}" target="\${link.target}">
                                <img align="\${img.align}" alt="FormaSUS" title="FormaSUS" src="\${img.src}">
                            </a>
                            The <strong>Scholarship Program of the Unified Health System - FormaSUS</strong> has as the goal offering full scholarships in private institutions for undergraduate and technical degree related with health field.
                            The scholarships are destined to students who completed the high school in public schools or students who had full scholarship in a private school in Pernambuco state. The institutions that offer the scholarships for the program are allowed to use the public space for the training of their health professionals.`
                        ],
                        keywords: [
                            "Scholarship Program",
                            "Higher Education",
                            "Technical Education",
                            "Health Unique System",
                        ]
                    },
                    "business_front_end_developer_ripen": {
                        summary: "Front-End Developer, Ripen - Recife",
                        description: [],
                        importantPoints: [
                            "development of responsive institutional websites, using HTML, CSS, LESS, Javascript and JQuery;",
                            "I was responsible for designing the pages."
                        ],
                        keywords: [
                            "HTML5", "CSS3", "LESS", "Javascript", "JQuery"
                        ],
                    },
                    "graduation_ads_ifpe": {
                        summary: "Technologist Degree in System Analysis and Development",
                        description: [
                            "Federal Institute of Education, Science and Technology of Pernambuco - Campus Recife"
                        ],
                        keywords: [
                            "Requirements Analysis",
                            "Java",
                            "Maven",
                            "JSP",
                            "JSF",
                            "JPA",
                            "Hibernate",
                            "MySQL",
                            "JUnit",
                            "Git",
                            "UML"
                        ]
                    },
                    "monitoring_ifpe_oop": {
                        summary: "Monitoring of Object Oriented Programming, IFPE - Recife",
                        description: [
                            "Monitor in the discipline Language of Object Oriented Programming, using Java."
                        ],
                        keywords: [
                            "Object Oriented Programming",
                            "Java"
                        ]
                    },
                    "graduation_ads_ifpb": {
                        summary: "Technologist Degree in System Analysis and Development",
                        description: [
                            "Federal Institute of Education, Science and Technology of Paraíba - Campus Monteiro"
                        ],
                        keywords: [
                            "Algorithms",
                            "Data Structures",
                            "Object Oriented Programming",
                            "Python",
                            "Java",
                            "MySQL",
                        ],
                        end: "2015 (Transferred)"
                    },
                },

                options: {
                    unfinished: "Today",
                }
            }
        },
        footer: {
            linkCategories: {
                siteLinks: {
                    title: "Site Links",
                    links: {
                        home: "Home",
                        whoIAm: "${getMessage('sections.whoIAm.title')}",
                        timeline: "${getMessage('sections.timeline.title')}",
                        skills: "${getMessage('sections.skills.title')}",
                        knowledge: "${getMessage('sections.knowledge.title')}",
                        contact: "${getMessage('sections.contact.title')}",
                    }
                },
                respositories: {
                    title: "Respositories",
                    links: {
                        github: "GitHub",
                        gitlab: "GitLab",
                        bitbucket: "Bitbucket",
                        dockerHub: "Docker Hub",
                        sonarcloud: "SonarCloud",
                        npm: "npm",
                        gist: "Gist",
                    }
                },
                social: {
                    title: "Social",
                    links: {
                        linkedin: "LinkedIn",
                        twitter: "Twitter",
                        instagram: "Instagram",
                        facebook: "Facebook",
                        stackoverflow: "Stackoverflow",
                    }
                },
            }
        }
    }
};

export default messages;
