import React, { useEffect } from 'react';

// See https://developers.facebook.com/docs/plugins/like-button
const LikeButton = ({ id, href, width, height, layout = "standard", action = "like", size = "small", showFaces = "false", share = "true", color = "light" }) => {

    useEffect(() => {
        if (id && height) {
            const fixButtonHeight = () => {
                const iframe = document.querySelector(`[data-id='${id}'] iframe`);
                if (iframe) {
                    iframe.style.height = `${height}px`;
                } else {
                    setTimeout(fixButtonHeight, 2000);
                }
            };

            fixButtonHeight();
        }
    }, [id, height]);

    return (
        <div className="fb-like"
            data-id={id || ""}
            data-href={href || ""}
            data-width={width || ""}
            data-height={height || ""}
            data-layout={layout}
            data-action={action}
            data-size={size}
            data-show-faces={showFaces}
            data-share={share}
            data-colorscheme={color} />
    );
};

export default LikeButton;
