import React from 'react';

import { Message } from '@seudev/x-i18n';

import HeroSection from '../../components/hero-section/HeroSection';
import './knowledge.scss';

const Column = props => (
    <div className="column is-12-mobile-x is-6-mobile-m is-4-mobile is-3-tablet is-2-desktop">
        <strong>{props.children}</strong>
    </div>
);

const Knowledge = () => (
    <HeroSection id="knowledge" title="sections.knowledge.title" addBodyClass="is-mobile">
        <Message as={Column} id="sections.knowledge.content" />
    </HeroSection>
);

export default Knowledge;
