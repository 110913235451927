import React from 'react';

// See https://publish.twitter.com
const FollowButton = ({ userId, size, showName = true, showCount = true }) => (
    <a href={`https://twitter.com/${userId}`}
        className="twitter-follow-button"
        data-size={size || ""}
        data-show-screen-name={showName}
        data-show-count={showCount}>
        Follow @{userId}
    </a>
);

export default FollowButton;
