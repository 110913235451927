import { DateTime } from 'luxon';


export const getLocalDurationFromISO = date => DateTime.local().diff(DateTime.fromISO(date));
export const getLocalDuration = date => DateTime.local().diff(date);

export const formatDuration = (duration, getMessage) => {
    const shiftedDuration = duration.shiftTo('years', 'months', 'days');
    const years = shiftedDuration.get('years');
    const months = shiftedDuration.get('months');
    const formattedYears = getMessage('unit.time.year', { value: years });
    const formattedMonths = getMessage('unit.time.month', { value: months });
    const and = getMessage('unit.time.and');

    let result = "";

    if (years > 0) {
        result += `${years} ${formattedYears}`;
    }
    if (years > 0 && months > 0) {
        result += ` ${and} `
    }
    if (months > 0) {
        result += `${months} ${formattedMonths}`;
    }
    return result;
};
