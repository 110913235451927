import React, { useRef, useState, createContext, useContext } from 'react';

import './loading.scss';

export const LoadingStatus = {
    ENABLED: 'enabled',
    DISABLING: 'disabling',
    DISABLED: 'disabled'
};

const INITIAL_STATE = { status: LoadingStatus.DISABLED, timeoutExceeded: false };

const LoadingContext = createContext(INITIAL_STATE);

export const useLoadingContext = () => useContext(LoadingContext)

export const LoadingContextProvider = ({ children }) => {
    const stateRef = useRef();
    const [state, setState] = useState(INITIAL_STATE);

    stateRef.current = state;

    const disable = (timeoutExceeded = false, delay = 1000) => {
        setState(s => ({ ...s, status: LoadingStatus.DISABLING, timeoutExceeded }));

        setTimeout(() => {
            if (stateRef.current?.status === LoadingStatus.DISABLING) {
                setState(s => ({ ...s, status: LoadingStatus.DISABLED, timeoutExceeded: true }));
            }
        }, delay);
    };

    const enable = (timeout, disableDelay) => {
        setState(s => ({ ...s, status: LoadingStatus.ENABLED, timeoutExceeded: false }));

        if (timeout > 0) {
            setTimeout(() => {
                if (stateRef.current?.status === LoadingStatus.ENABLED) {
                    disable(true, disableDelay);
                }
            }, timeout);
        }
    };

    const contextValue = {
        ...state,
        enable,
        disable,
    };

    return (
        <LoadingContext.Provider value={contextValue}>
            {children}
        </LoadingContext.Provider>
    );
};

const Loading = ({ full }) => {
    const context = useLoadingContext();

    return (
        <div className={`loading-container ${full ? 'full' : ''} ${context.status}`}>
            <div>
                <span className="pulse one" />
                <span className="pulse two" />
                <span className="pulse three" />
                <i className="fa fa-headphones" />
            </div>
            <div />
        </div>
    );
};

export default Loading;
