import leadershipIcon from '../../assets/skills/leadership-64px.png';
import businessAnalysisIcon from '../../assets/skills/business-analysis-64px.png';
import creativityIcon from '../../assets/skills/creativity-64px.png';
import architectureDesignIcon from '../../assets/skills/architecture-design-64px.png';
import refactoringIcon from '../../assets/skills/refactoring-64px.png';
import databaseModelingIcon from '../../assets/skills/database-modeling-64px.png';
import sqlScriptsIcon from '../../assets/skills/sql-scripts-65px.png';
import webDesignIcon from '../../assets/skills/web-design-64px.png';
import javaExpertIcon from '../../assets/skills/java-expert-64px.png';

const skills = [
    {
        id: 'businessAnalysis',
        icon: businessAnalysisIcon,
        className: 'is-4',
    },
    {
        id: 'leadership',
        icon: leadershipIcon,
        className: 'is-4',
    },
    {
        id: 'creativity',
        icon: creativityIcon,
        className: 'is-4',
    },
    {
        id: 'architectureDesign',
        icon: architectureDesignIcon,
        className: 'is-4',
    },
    {
        id: 'refactoring',
        icon: refactoringIcon,
        className: 'is-4',
    },
    {
        id: 'databaseModeling',
        icon: databaseModelingIcon,
        className: 'is-4',
    },
    {
        id: 'sqlScripts',
        icon: sqlScriptsIcon,
        className: 'is-4',
    },
    {
        id: 'webDesign',
        icon: webDesignIcon,
        className: 'is-4',
    },
    {
        id: 'javaExpert',
        icon: javaExpertIcon,
        className: 'is-12',
    },
];

export default skills;
