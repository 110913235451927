/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';

import { useI18n } from '@seudev/x-i18n';
import { Message } from '@seudev/x-i18n';
import translateIcon from '../../assets/navbar/translate.png';

const LanguageDropdown = ({ onChange }) => {
    const { lang, langs, setLang } = useI18n();

    return (
        <div className="navbar-item has-dropdown is-hoverable">
            <a className="navbar-link" onClick={e => e.preventDefault()}>
                <span className="icon translate">
                    <img src={translateIcon} alt="Translate" />
                </span>
                <span>
                    <Message id={`lang.${lang}`} />
                </span>
            </a>
            <div className="navbar-dropdown">
                {langs.map(lang => (
                    <a key={lang}
                        className="navbar-item"
                        onClick={e => {
                            e.preventDefault();
                            setLang(lang);
                            onChange(e, lang);
                        }}>
                        <Message id={`lang.${lang}`} />
                    </a>
                ))}
            </div>
        </div>
    );
};

export default LanguageDropdown;
