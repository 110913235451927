import { DateTime } from 'luxon';

import formasusLogo from '../../assets/timeline/project/formasus.png';

const events = [
    {
        id: "monitoring_ifpe_oop",
        open: true,
        type: "monitoring",
        start: "2016-09-01T00:00:00-03:00",
        end: "2016-12-30T00:00:00-03:00"
    },
    {
        id: "project_formasus",
        open: true,
        type: "project",
        link: {
            href: "https://formasus.saude.pe.gov.br",
            target: "_blank"
        },
        img: {
            src: formasusLogo,
            align: "left"
        },
        start: "2017-03-01T00:00:00-03:00",
        end: "2018-08-01T00:00:00-03:00"
    },
    {
        id: "business_full_stack_developer_montreal",
        open: true,
        type: "business",
        start: "2017-03-01T00:00:00-03:00",
        end: "2019-08-16T00:00:00-03:00",
    },
    {
        id: "business_senior_full_stack_developer_g4f",
        open: true,
        type: "business",
        start: "2019-09-01T00:00:00-03:00",
        untilNow: true
    },
    {
        id: "business_requirements_analyst_montreal",
        open: true,
        type: "business",
        start: "2018-09-01T00:00:00-03:00",
        end: "2019-08-16T00:00:00-03:00",
    },
    {
        id: "business_front_end_developer_ripen",
        open: true,
        type: "business",
        start: "2017-01-01T00:00:00-03:00",
        end: "2017-02-01T00:00:00-03:00",
    },
    {
        id: "graduation_ads_ifpb",
        open: true,
        type: "graduation",
        start: "2013-01-01T00:00:00-03:00",
        end: "2015-01-01T00:00:00-03:00",
    },
    {
        id: "graduation_ads_ifpe",
        open: true,
        type: "graduation",
        start: "2015-01-01T00:00:00-03:00",
        end: "2019-05-01T00:00:00-03:00"
    },
];

export const findDate = (type, date, reducer) => {
    const now = DateTime.local();
    return events.filter(e => e.type === type)
        .map(e => e[date] ? DateTime.fromISO(e[date]) : now)
        .reduce(reducer);
};

export const findFirstStart = type => findDate(type, "start", (x, y) => x < y ? x : y);
export const findLastEnd = type => findDate(type, "end", (x, y) => x > y ? x : y);

export default events;
