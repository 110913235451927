import React from 'react';

import Footer from '../../components/footer/Footer'
import data from './data';

const SimpleFooter = () => (
    <Footer data={data} />
);

export default SimpleFooter;
