import React from 'react';

import { Message, getMessage } from '@seudev/x-i18n';

import { formatDuration, getLocalDurationFromISO } from '../../helpers/time';
import HeroSection from '../../components/hero-section/HeroSection';
import skills from './data';
import './skills.scss';


const Skills = () => {
    const javaExperience = formatDuration(getLocalDurationFromISO("2015-01-01T00:00:00-03:00"), getMessage);

    const params = { javaExperience };
    return (
        <HeroSection id="skills" title="sections.skills.title">
            {skills.map(skill => (
                <div key={skill.id} className={`column has-text-centered ${skill.className || ''}`}>
                    <figure className="image has-image-centered is-64x64">
                        <img src={skill.icon} alt={skill.title} />
                    </figure>
                    <h3 className="title"><Message id={`sections.skills.content[${skill.id}].title`} /></h3>
                    <div className="content"><Message id={`sections.skills.content[${skill.id}].description`} params={params} /></div>
                </div>
            ))}
        </HeroSection>
    );
};

export default Skills;
