import React from 'react';

import { Message } from '@seudev/x-i18n';

import './footer.scss';

const Link = ({ id, className, href, target, icon, category }) => (
    <li>
        <div className={`link ${className || ''}`}>
            <a href={href} target={target}>
                {icon && <i className={`icon ${icon}`}></i>}
                <span><Message id={`footer.linkCategories[${category}].links[${id}]`} /></span>
            </a>
        </div>
    </li>
);

const LinkCategory = ({ id, className, links, buttons }) => (
    <div className={`column is-6-mobile-m is-narrow ${className || ''}`}>
        <h3 className="title"><Message id={`footer.linkCategories[${id}].title`} /></h3>
        <ol className="list-unstyled">
            {links.map(link => (
                <Link key={link.id} category={id} {...link} />
            ))}
            {(buttons || []).map((Button, index) => (
                <li key={index}>
                    <Button />
                </li>
            ))}
        </ol>
    </div>
);

const Footer = ({ data }) => {
    const listenMusic = () => {
        const index = Math.floor(Math.random() * data.musicStreams.length);
        window.open(data.musicStreams[index]);
    };

    return (
        <footer id="main-footer" className="footer">
            <div className="content">
                <div className="container">
                    <div className="columns is-centered is-multiline is-mobile">
                        {data.linkCategories.map(category => (
                            <LinkCategory key={category.id} {...category} />
                        ))}
                    </div>
                    <div className="container has-text-centered">
                        <i className="fa fa-headphones listen-music" onClick={listenMusic} />
                        <div className="copyright">
                            <span>{data.copyright}</span>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );

};

export default Footer;
