import React from 'react';

import { Message } from '@seudev/x-i18n';

const HeroSection = ({ id, sectionClass, style = {}, title, bodyClass, addBodyClass, children }) => (
    <section id={`${id}-section`} className={`hero is-medium ${sectionClass || ''}`} style={style || {}}>
        <span id={id} className="anchor"></span>
        {title &&
            <div className="hero-head">
                <h2 className="title"><Message id={title} /></h2>
            </div>
        }
        <div className="hero-body">
            <div className="container">
                <div className={`columns ${bodyClass || 'is-vcentered is-centered is-multiline'} ${addBodyClass || ''}`}>
                    {children}
                </div>
            </div>
        </div>
    </section>
);

export default HeroSection;
