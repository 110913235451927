import React from 'react';

import { DateTime } from 'luxon';
import LikeButton from '../../components/facebook/LikeButton';
import FollowButton from '../../components/twitter/FollowButton';

const currentYear = DateTime.local().year;

const data = {
    copyright: `Copyright © 2019-${currentYear} Thomás Sousa Silva`,
    linkCategories: [
        {
            id: 'siteLinks',
            links: [
                { id: 'home', href: '#home' },
                { id: 'whoIAm', href: '#who-i-am' },
                { id: 'timeline', href: '#timeline' },
                { id: 'skills', href: '#skills' },
                { id: 'knowledge', href: '#knowledge' },
                { id: 'contact', href: '#contact' },
            ],
        },
        {
            id: 'respositories',
            links: [
                {
                    id: 'github',
                    href: 'https://github.com/ThomasSousa96',
                    icon: 'fab fa-github',
                    target: '_blank'
                },
                {
                    id: 'gitlab',
                    href: 'https://gitlab.com/ThomasSousa96',
                    icon: 'fab fa-gitlab',
                    target: '_blank'
                },
                {
                    id: 'bitbucket',
                    href: 'https://bitbucket.org/ThomasSousa96',
                    icon: 'fab fa-bitbucket',
                    target: '_blank'
                },
                {
                    id: 'dockerHub',
                    href: 'https://hub.docker.com/u/thomassousa96',
                    icon: 'fab fa-docker',
                    target: '_blank'
                },
                {
                    id: 'sonarcloud',
                    href: 'https://sonarcloud.io/organizations/thomassousa96-github',
                    icon: 'sonarcloud',
                    target: '_blank'
                },
                {
                    id: 'npm',
                    href: 'https://www.npmjs.com/~thomassousa96',
                    icon: 'fab fa-npm',
                    target: '_blank'
                },
                {
                    id: 'gist',
                    href: 'https://gist.github.com/ThomasSousa96',
                    icon: 'fab fa-github-alt',
                    target: '_blank'
                },
            ]
        },
        {
            id: "social",
            className: "is-12-mobile-m",
            links: [
                {
                    id: 'linkedin',
                    href: 'https://www.linkedin.com/in/thomassousa96',
                    icon: 'fab fa-linkedin',
                    target: '_blank'
                },
                {
                    id: 'twitter',
                    href: 'https://twitter.com/thomassousa96',
                    icon: 'fab fa-twitter',
                    target: '_blank'
                },
                {
                    id: 'facebook',
                    href: 'https://www.facebook.com/thomas.sousa.silva.96',
                    icon: 'fab fa-facebook-square',
                    target: '_blank'
                },
                {
                    id: 'stackoverflow',
                    href: 'https://stackoverflow.com/users/story/5666441',
                    icon: 'fab fa-stack-overflow',
                    target: '_blank'
                },
                {
                    id: 'instagram',
                    href: 'https://www.instagram.com/thomassousa96/',
                    icon: 'fab fa-instagram',
                    target: '_blank'
                },
            ],
            buttons: [
                props => (
                    <FollowButton userId="ThomasSousa96" />
                ),
                props => (
                    <LikeButton id="likeButton" width="225" height="60" color="dark" />
                )
            ]
        }
    ],
    musicStreams: [
        "https://www.youtube.com/watch?v=5lMmnfVylEE",
        "https://www.youtube.com/watch?v=IvuwTft-0cM",
        "https://www.youtube.com/watch?v=eQdA8dvsgQs",
        "https://www.youtube.com/watch?v=ew5fOuedpJk",
        "https://www.youtube.com/watch?v=DY_rFed96mg",
        "https://www.youtube.com/watch?v=Ehnp6MzX1v4",
        "https://www.youtube.com/watch?v=ahEycutrkDw",
        "https://www.youtube.com/watch?v=WsDyRAPFBC8",
        "https://www.youtube.com/watch?v=ql4S8z1jW8I",
        "https://www.youtube.com/watch?v=d8Oc90QevaI",
        "https://www.youtube.com/watch?v=KvRVky0r7YM",
        "https://www.youtube.com/watch?v=5qky3L2Q6G4",
    ]
};

export default data;
