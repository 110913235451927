import React, { useState, useEffect } from 'react';

import { DateTime } from 'luxon';
import { Message, useI18n } from '@seudev/x-i18n';
import { formatDate } from '@seudev/x-i18n';

import './timeline.scss';

const Line = ({ index }) => {
    if (index === 0) {
        return false;
    }
    return (
        <div className="timeline">
            <div className="corner">
                <div className="line"></div>
            </div>
            <div className="middle">
                <div className="line"></div>
            </div>
            <div className="corner">
                <div className="line"></div>
            </div>
        </div>
    );
};

const LabelContainer = ({ timelineId, event: { start, end, finished } }) => (
    <div className="label">
        <div className="circle">
            <div className="time-interval">
                <time className="end" dateTime={finished ? end.year.toString() : ""}>
                    {finished ? end.year : <Message id={`timelines[${timelineId}].options.unfinished`} />}
                </time>
                <time className="start" dateTime={start.year.toString()}>{start.year}</time>
            </div>
        </div>
    </div>
);

const DetailsContainer = ({ timelineId, event, event: { id, start, end, finished }, options }) => {
    const { getMessage } = useI18n();
    const dateOptions = options.type[event.type].date;
    const messageIdPrefix = `timelines[${timelineId}].events[${id}]`;
    const importantPoints = getMessage(`${messageIdPrefix}.importantPoints`, event, []);

    return (
        <details open={event.open}>
            <summary>
                <Message id={`${messageIdPrefix}.summary`} params={event} />
            </summary>
            <Message as="p" rawHtml id={`${messageIdPrefix}.description`} params={event} />
            {importantPoints.length > 0 && (
                <div className="important-points-container">
                    <ul>
                        {importantPoints.map(p => <li key={p}>{p}</li>)}
                    </ul>
                </div>
            )}
            <div className="keyword-container">
                <Message as="strong" id={`${messageIdPrefix}.keywords`} params={event} />
            </div>
            <div className="time-interval">
                <time className="start" dateTime={formatDate(start, dateOptions, "en")}>
                    <Message id={`${messageIdPrefix}.start`} default={formatDate(start, dateOptions)} />
                </time>
                <span className="separator">-</span>
                <time className="end" dateTime={finished ? formatDate(end, dateOptions, "en") : ""}>
                    <Message id={`${messageIdPrefix}.end`} default={finished ? formatDate(end, dateOptions) : ""} />
                </time>
            </div>
        </details>
    );
};

const TimelineSection = ({ index, event: { id, type, finished }, children }) => {
    const label = children[0];
    const details = children[1];
    const sortedChildren = (((index % 2) === 0) ? [label, details] : [details, label]);

    return (
        <section event-id={id} type={type} finished={finished.toString()}>
            {sortedChildren}
        </section>
    );
};

const compareEvents = (a, b, reversed) => {
    if (a.equals(b)) {
        return 0;
    }
    return (((a > b) ? 1 : -1) * (reversed ? -1 : 1));
};

const mapEvents = events => events.map(event => ({
    ...event,
    start: DateTime.fromISO(event.start),
    finished: (event.end != null),
    end: ((event.end != null) ? DateTime.fromISO(event.end) : null),
}));

const sortEvents = (events, options) => events.sort((event1, event2) => {
    const now = DateTime.local();
    const end1 = ((event1.end != null) ? event1.end : (event1.untilNow ? now : null));
    const end2 = ((event2.end != null) ? event2.end : (event2.untilNow ? now : null));

    let result = compareEvents(end1, end2, options.reversed);
    if (result === 0) {
        result = compareEvents(event1.start, event2.start, options.reversed);
    }
    return result;
});

const Timeline = ({ id, events: rawEvents = [], options = {} }) => {
    const [events, setEvents] = useState([]);

    useEffect(() => {
        const events = mapEvents(rawEvents);
        sortEvents(events, options);
        setEvents(events);
    }, [rawEvents, options]);

    const elements = events.map((event, index) => (
        <React.Fragment key={index}>
            <Line index={index} />
            <TimelineSection index={index} event={event}>
                <LabelContainer timelineId={id} event={event} />
                <DetailsContainer timelineId={id} event={event} options={options} />
            </TimelineSection>
        </React.Fragment>
    ));

    return (
        <div className="timeline-outer-container">
            <span id={id} className="anchor"></span>
            <h2><Message id={`timelines[${id}].title`} /></h2>
            <div className="timeline-container">
                {elements}
            </div>
        </div>
    );
};

export default Timeline;
