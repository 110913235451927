import React from 'react';

import { Message } from '@seudev/x-i18n';

import HeroSection from '../../components/hero-section/HeroSection';
import './contact.scss';

const email = "thomas@seudev.com";

const Contact = () => (
    <HeroSection id="contact">
        <div className="column is-hidden-mobile has-text-centered">
            <i className="fa fa-at"></i>
        </div>
        <div className="column is-full-mobile is-two-thirds-tablet has-text-justified">
            <h2 className="title is-size-2"><Message id="sections.contact.title" /></h2>
            <h3 className="subtitle"><Message id="sections.contact.subtitle" /></h3>
            <p>
                <Message id="sections.contact.description" />
                <a href={`mailto:${email}`} alt="contact email" className="has-text-centered-mobile is-size-3">{email}</a>
            </p>
        </div>
    </HeroSection>
);

export default Contact;
