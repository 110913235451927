import React from 'react';

import Timeline from '../../components/timeline/Timeline';
import events from './data';
import './timeline.scss';

const options = {
    reversed: true,
    type: {
        graduation: {
            date: {
                year: "numeric"
            }
        },
        business: {
            date: {
                year: "numeric",
                month: "2-digit"
            }
        },
        project: {
            date: {
                year: "numeric"
            }
        },
        course: {
            date: {
                year: "numeric",
                month: "2-digit"
            }
        },
        monitoring: {
            date: {
                year: "numeric",
                month: "2-digit"
            }
        },
        certification: {
            date: {
                year: "numeric",
                month: "2-digit"
            }
        },
        award: {
            date: {
                year: "numeric"
            }
        }
    }
};

const MainTimeline = () => (
    <Timeline id="timeline" events={events} options={options} />
);

export default MainTimeline;
