import React from 'react';

import { useI18n, Message } from '@seudev/x-i18n';

import HeroSection from '../../components/hero-section/HeroSection';
import profilePhoto from '../../assets/who-i-am/thomas-sousa-silva.jpg';
import { formatDuration, getLocalDurationFromISO } from '../../helpers/time';

import { findFirstStart, findLastEnd } from '../timeline/data.js';
import './who-i-am.scss';

const yearsOld = getLocalDurationFromISO("1994-07-11T00:00:00-03:00").shiftTo('years', 'months').get('years');
const businessExperienceDuration = findLastEnd("business").diff(findFirstStart("business"));

const WhoIAm = () => {
    const { getMessage } = useI18n();
    const brand = getMessage('brand');
    const businessExperience = formatDuration(businessExperienceDuration, getMessage);

    return (
        <HeroSection id="who-i-am" addBodyClass="is-desktop">
            <div className="column">
                <figure className="image has-image-centered">
                    <img src={profilePhoto} alt={brand} />
                </figure>
            </div>
            <div className="column is-two-thirds-desktop has-text-justified">
                <h2 className="title"><Message id="sections.whoIAm.title" /></h2>
                <Message as="p" id="sections.whoIAm.content" params={{ yearsOld, businessExperience }} />
            </div>
        </HeroSection>
    );
};

export default WhoIAm;
