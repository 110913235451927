/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useRef } from 'react';

import { Message } from '@seudev/x-i18n';

import { useLoadingContext } from '../../components/loading/Loading'
import './home.scss';

export const links = [
    { href: "#who-i-am", title: "sections.whoIAm.title" },
    { href: "#timeline", title: "sections.timeline.title" },
    { href: "#skills", title: "sections.skills.title" },
    { href: "#knowledge", title: "sections.knowledge.title" },
    { href: "#contact", title: "sections.contact.title" },
];

const Button = props => (
    <div>
        <a key={props.href} href={props.href}><Message id={props.title} /></a>
    </div>
);

const readyDelay = 2;

const videoConfig = {
    videoId: 'HkyVTxH2fIM',
    playerVars: {
        // https://developers.google.com/youtube/player_parameters
        // https://developers.google.com/youtube/youtube_player_demo
        autoplay: 1,
        cc_load_policy: 0,
        controls: 0,
        fs: 0,
        iv_load_policy: 3,
        disablekb: 1,
        loop: 1,
        modestbranding: 1,
        rel: 0,
        showinfo: 0,
        mute: 1,
        start: (42 - readyDelay),
        origin: window.location.origin
    },
};

const useYouTubePlayer = ({ containerId, readyDelay, loadingTimeout, videoConfig }) => {
    const loading = useLoadingContext();
    const [mute, setMute] = useState(true);
    const [ready, setReady] = useState(false);
    const [fail, setFail] = useState(false);
    const playerRef = useRef(null);

    const toggleMute = () => {
        if (playerRef.current.isMuted()) {
            playerRef.current.setVolume(100);
            playerRef.current.unMute();
        } else {
            playerRef.current.mute();
        }
        setMute(!mute);
    };

    useEffect(() => {
        loading.enable(loadingTimeout);

        window.onYouTubePlayerAPIReady = () => {
            const onReady = event => {
                playerRef.current = event.target;
                playerRef.current.playVideo();
            };

            const onStateChange = event => {
                if (event.data === window.YT.PlayerState.PLAYING) {
                    setTimeout(() => {
                        setReady(true);
                        loading.disable();
                    }, (readyDelay * 1000));
                } else if (event.data === window.YT.PlayerState.ENDED) {
                    playerRef.current.seekTo(videoConfig.playerVars.start);
                }
            };

            const onError = event => {
                loading.disable();
                setFail(true);
                console.error(event.data);
            };

            // https://developers.google.com/youtube/iframe_api_reference
            new window.YT.Player(containerId, {
                ...videoConfig,
                events: {
                    onReady,
                    onStateChange,
                    onError
                }
            });
        };
    }, [containerId, loadingTimeout, readyDelay, videoConfig]);

    return { mute, ready, fail, toggleMute };
};

const Home = () => {
    const { mute, ready, fail, toggleMute } = useYouTubePlayer({ containerId: 'home-video', readyDelay, loadingTimeout: 10000, videoConfig });
    const loading = useLoadingContext();

    return (
        <section id="home-section">
            <span id="home" className="anchor"></span>
            <div id="home-video-container" className={`${ready ? 'ready' : ''}`}>
                <div id="home-video" />
            </div>
            <div className={`video-foreground ${fail || loading.timeoutExceeded ? 'fallback' : ''}`} />
            <div className="home-body container">
                <div className="columns is-vcentered is-centered is-multiline is-mobile">
                    <div className="column is-6 is-12-mobile-m">
                        <h1 className="title"><Message id="brand" /></h1>
                    </div>
                    <div className="column is-6 is-hidden-mobile">
                        <div className="button-container">
                            {links.map(link => (
                                <Button key={link.href} {...link} />)
                            )}
                        </div>
                    </div>
                    <div className="column is-full">
                        <h2 className="subtitle"><Message id="slogan" /></h2>
                        {ready &&
                            <i className={`mute-volume fa fa-volume-${mute ? 'mute' : 'up'}`} onClick={toggleMute}></i>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Home;
